.rebate {
  width: 100%;

  .rebate-row {
    display: flex;
    justify-content: space-between;

    .acc_left {
      line-height: normal;
      display: flex;
      align-items: center;
    }

    /deep/ .el-col {
      border-radius: 8px;
      overflow: hidden;
    }

    /deep/.el-col-2 {
      @include screen-mobile() {
        width: 100%;
      }

      .el-icon-arrow-right {
        font-size: 70px;
        color: $yellow1;
        margin-top: 50px;

        @include screen-mobile() {
          transform: rotate(90deg);
          margin-top: 0px;
        }
      }
    }

    .account_number {
      display: flex;
      justify-content: space-between;

      /deep/ .el-select {
        box-sizing: content-box !important;
        align-self: flex-start;
        width: 200px;
        height: 60px !important;
      }

      /deep/ .accounts_select {
        // height: 45px !important;
        background: $black1;
        border-left: 1px solid $black3;
        color: $white;

        /deep/ .el-input__inner {
          border: none !important;
          height: 45px !important;
          padding: 0 20px;
          color: $white;
          border-radius: 0;
        }

        & /deep/ .el-input .el-select__caret {
          color: white !important;

          &:before {
            content: '\e6e1' !important;
          }
        }
      }
    }

    /deep/ .el-card {
      // height: 100%;
      border: none;
      border-radius: 0;
      background: none;

      .el-card__body {
        // height: 100%;
        padding: 0;
        background: $theme-hue;
        width: 100%;

        .content {
          padding: 0 20px;
          height: 125px;

          .btn-container {
            align-items: center;

            @include screen-mobile() {
              flex-direction: column;
            }

            @media (max-width: 1150px) {
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .next-btn {
                margin-left: 0;
                margin-top: 5px;
              }
            }
          }

          .currency {
            font-size: 27px;
            color: $white;
            margin-top: 5px;
            text-overflow: ellipsis;
          }

          .el-button {
            align-self: center;
          }


          .el-button--primary {
            @include rtl-sass-prop(margin-right, margin-left, 0);
            @include rtl-sass-prop(margin-left, margin-right, 0);
          }
        }
      }
    }
  }

  .dialog {
    em {
      font-size: 50px;
    }

    p {
      word-break: break-word;
    }
  }
}

@media (max-width: 992px) {
  .rebate {
    .middle {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .rebate {
    .rebate-row {
      display: block;
      justify-content: unset;
    }
  }
}

@media (max-width: 550px) {
  .rebate {
    margin-bottom: 35px;
  }
}