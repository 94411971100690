.update_container {
  display: flex;
  border-radius: 3px;
  overflow: hidden;
  flex-shrink: 0;

  /deep/.el-select {
    width: 30% !important;
    flex-shrink: 0;
    background-color: $black2;

    .el-input {
      @include rtl-sass-prop(border-right, border-left, 1px solid $light-white);
    }

    @include screen-mobile1() {
      width: 100% !important;

      .el-input {
        border-bottom: 1px solid $light-white;
      }
    }
  }

  @include screen-mobile1() {
    flex-direction: column;
    align-items: center;
  }

  .date_container {
    width: 70%;
    background: $black2;
    font-size: 16px;

    @include screen-mobile1() {
      width: 100%;
    }

    @include screen-mobile1() {
      flex-direction: column;
      align-items: center;
    }

    .calendar_start_date {
      /deep/ .el-input__inner {
        border: none;
        border-radius: 0;
        font-size: 16px;
        height: 45px !important;
      }
    }

    .calendar_to {
      padding: 0 20px;
      text-align: center;
      color: $yellow1;
      line-height: 40px;
      border-left: 1px solid $light-white;
      border-right: 1px solid $light-white;

      @include screen-mobile1() {
        width: 100%;
        border-top: 1px solid $light-white;
        border-bottom: 1px solid $light-white;
      }
    }

    .calendar_end_date {
      @include screen-mobile1() {
        border: none;
      }

      /deep/ .el-input__inner {
        border: none;
        height: 45px !important;
        font-size: 16px;
        @include rtl-sass-value(border-radius,
          0 $border-radius $border-radius 0 !important,
          $border-radius 0 0 $border-radius !important);
      }
    }
  }

  .calendar_start_date,
  .calendar_end_date {
    // width: 32%;
    width: 200px;

    @include screen-mobile1() {
      width: 100%;
    }

    /deep/ .el-input {
      width: 100%;
      font-size: 15px;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;

      .el-icon-date {
        position: relative;
        @include rtl-sass-prop(left, right, 10px);
      }

      .el-input__prefix .el-icon-date:before {
        content: '';
        background: url(~@/assets/uploads/date.png) no-repeat;
        background-size: 15px 18px;
        background-position: 10px;
        width: 30px;
        height: 100%;
        display: inline-block;
      }

      .el-input__inner {
        cursor: pointer;
      }
    }
  }
}